import React from "react";

const LogoSvg = () => {
  return (
    <svg
      className="w-full block dark:hidden"
      viewBox="0 0 65 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
	<path fill="currentColor" d="M7.6,10.2H4.7L3.6,6.7H3.5v3.5H1V0.9h2.5v3.9h0.1l1.3-3.9h2.8L5.9,5.7L7.6,10.2z"/>
	<path fill="currentColor" d="M14.9,10.2h-2.5l-0.3-1.5h-1.8l-0.3,1.5H7.7l1.7-9.3h3.8L14.9,10.2z M11.8,7l-0.5-3.3h-0.1L10.7,7H11.8z"/>
	<path fill="currentColor" d="M24.7,10.2h-2.3V5.4h-0.1l-0.9,4.8h-2.4L18,5.4h-0.1v4.8h-2.3l0.3-9.3h3.4l0.8,5h0.1l0.8-5h3.3L24.7,10.2z"/>
	<path fill="currentColor" d="M29.1,7.2h-0.7v3h-2.5V0.9h3c1.1,0,1.9,0.2,2.4,0.7c0.5,0.5,0.8,1.3,0.8,2.4S31.8,6,31.3,6.5C30.8,7,30.1,7.2,29.1,7.2z
		 M29.4,5.2c0.1-0.2,0.1-0.5,0.1-0.9s0-0.7-0.1-0.9c-0.1-0.2-0.3-0.3-0.6-0.3h-0.4v2.4h0.4C29.1,5.4,29.3,5.3,29.4,5.2z"/>
	<path fill="currentColor" d="M33.4,1.9c0.6-0.7,1.5-1.1,2.8-1.1c1.3,0,2.2,0.4,2.8,1.1c0.6,0.7,0.8,1.9,0.8,3.6S39.6,8.4,39,9.2
		c-0.6,0.8-1.5,1.2-2.8,1.2c-1.3,0-2.2-0.4-2.8-1.2c-0.6-0.8-0.9-2-0.9-3.7S32.8,2.7,33.4,1.9z M37.1,3.6c-0.1-0.3-0.4-0.5-0.9-0.5
		c-0.4,0-0.7,0.2-0.9,0.5c-0.1,0.3-0.2,0.9-0.2,1.8v0.4c0,1,0.1,1.6,0.2,1.9c0.1,0.3,0.4,0.5,0.9,0.5c0.4,0,0.7-0.1,0.9-0.4
		c0.1-0.3,0.2-0.9,0.2-1.8V5.5C37.3,4.6,37.2,4,37.1,3.6z"/>
	<path fill="currentColor" d="M47.6,10.2h-2.5l-1.9-4.3h-0.1v4.3h-2.3V0.9h2.5l1.8,4.5h0.1V0.9h2.3V10.2z"/>
	<path fill="currentColor" d="M54.5,3.7c-1-0.3-1.7-0.4-2.1-0.4c-0.4,0-0.7,0.2-0.9,0.6c-0.2,0.4-0.2,1-0.2,1.8c0,0.8,0.1,1.5,0.2,1.8
		c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.4,0,0.8-0.1V6.9h-0.7V5.1h2.9v5.1h-1.8l-0.2-0.5h-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
		c-0.3,0.2-0.7,0.3-1.2,0.3c-2.1,0-3.1-1.6-3.1-4.7c0-1.6,0.3-2.8,0.8-3.6C50,1.2,51,0.8,52.3,0.8c1,0,1.9,0.2,2.7,0.5L54.5,3.7z"/>
	<path fill="currentColor" d="M1.3,12.1c0.5-0.5,1.2-0.8,2.1-0.8s1.8,0.1,2.8,0.3l-0.3,2.4c-1-0.2-1.7-0.3-2-0.3c-0.5,0-0.7,0.2-0.7,0.6
		c0,0.1,0.1,0.3,0.3,0.4C3.8,14.7,4,14.9,4.4,15c0.3,0.2,0.6,0.3,1,0.6c0.3,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.3,0.9,0.3,1.4
		c0,0.9-0.3,1.7-0.8,2.2c-0.5,0.5-1.2,0.8-2.2,0.8c-0.9,0-1.8-0.1-2.7-0.4L1,18.2c1.1,0.3,1.8,0.5,2.2,0.5s0.6-0.2,0.6-0.6
		c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.2-0.5-0.3-0.8-0.5c-0.3-0.2-0.6-0.3-1-0.6c-0.3-0.2-0.6-0.5-0.8-0.9c-0.2-0.4-0.3-0.9-0.3-1.4
		C0.6,13.3,0.8,12.6,1.3,12.1z"/>
	<path fill="currentColor" d="M7.9,12.4c0.6-0.7,1.5-1.1,2.8-1.1c1.3,0,2.2,0.4,2.8,1.1c0.6,0.7,0.8,1.9,0.8,3.6c0,1.7-0.3,2.9-0.9,3.7
		c-0.6,0.8-1.5,1.2-2.8,1.2c-1.3,0-2.2-0.4-2.8-1.2s-0.9-2-0.9-3.7C7.1,14.4,7.3,13.1,7.9,12.4z M11.6,14.1
		c-0.1-0.3-0.4-0.5-0.9-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.1,0.3-0.2,0.9-0.2,1.8v0.4c0,1,0.1,1.6,0.2,1.9c0.1,0.3,0.4,0.5,0.9,0.5
		c0.4,0,0.7-0.1,0.9-0.4c0.1-0.3,0.2-0.9,0.2-1.8V16C11.8,15.1,11.7,14.5,11.6,14.1z"/>
	<path fill="currentColor" d="M17.7,16.2c0,0.9,0.1,1.5,0.3,1.9c0.2,0.4,0.5,0.6,0.9,0.6c0.4,0,1-0.1,1.7-0.3l0.4,2.1c-0.8,0.3-1.6,0.4-2.4,0.4
		c-1.2,0-2.1-0.4-2.6-1.2c-0.5-0.8-0.8-2-0.8-3.5c0-1.6,0.3-2.8,0.9-3.6c0.6-0.8,1.5-1.2,2.8-1.2c0.8,0,1.5,0.2,2.2,0.5l-0.4,2.3
		c-0.8-0.3-1.3-0.4-1.6-0.4c-0.4,0-0.7,0.2-0.9,0.6C17.8,14.6,17.7,15.3,17.7,16.2z"/>
	<path fill="currentColor" d="M24.2,20.7h-2.5v-9.3h2.5V20.7z"/>
	<path fill="currentColor" d="M32.4,20.7h-2.5l-0.3-1.5h-1.8l-0.3,1.5h-2.4l1.7-9.3h3.8L32.4,20.7z M29.3,17.5l-0.5-3.3h-0.1l-0.5,3.3H29.3z"/>
	<path fill="currentColor" d="M37.9,20.7h-4.7v-9.3h2.6v7.2H38L37.9,20.7z"/>
	<path fill="currentColor" d="M3.3,26.6c0,0.9,0.1,1.5,0.3,1.9c0.2,0.4,0.5,0.6,0.9,0.6c0.4,0,1-0.1,1.7-0.3l0.4,2.1c-0.8,0.3-1.6,0.4-2.4,0.4
		c-1.2,0-2.1-0.4-2.6-1.2c-0.5-0.8-0.8-2-0.8-3.5C0.7,25,1,23.8,1.5,23s1.5-1.2,2.8-1.2c0.8,0,1.5,0.2,2.2,0.5l-0.4,2.3
		c-0.8-0.3-1.3-0.4-1.6-0.4c-0.4,0-0.7,0.2-0.9,0.6C3.4,25.1,3.3,25.8,3.3,26.6z"/>
	<path fill="currentColor" d="M11.8,31.2H7.1v-9.3h2.6v7.2H12L11.8,31.2z"/>
	<path fill="currentColor" d="M15.3,28.8c0.1,0.1,0.3,0.2,0.6,0.2c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.7v-6.2h2.6v6.4c0,1.1-0.3,1.8-0.9,2.3
		c-0.6,0.5-1.4,0.7-2.6,0.7c-1.1,0-2-0.2-2.5-0.7c-0.6-0.5-0.8-1.3-0.8-2.3v-6.4h2.6v6.2C15.1,28.5,15.2,28.7,15.3,28.8z"/>
	<path fill="currentColor" d="M27.2,28.5c0,1.8-1,2.7-2.9,2.7h-3.7v-9.3h3.6c0.9,0,1.6,0.2,2.1,0.6s0.7,1,0.7,1.8s-0.3,1.5-1,1.9v0.1
		C26.8,26.6,27.2,27.4,27.2,28.5z M24.2,25.7c0.1-0.1,0.1-0.4,0.1-0.7c0-0.4,0-0.6-0.1-0.7C24.1,24,23.9,24,23.7,24h-0.5v1.9h0.5
		C23.9,25.9,24.1,25.8,24.2,25.7z M24.3,29.3c0.1-0.1,0.2-0.4,0.2-0.8c0-0.4-0.1-0.7-0.2-0.9s-0.3-0.3-0.6-0.3h-0.5v2.2h0.5
		C24,29.5,24.2,29.5,24.3,29.3z"/>
    </svg>
  );
};

export default LogoSvg;
